import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import UserCard from "../UserCard"
import AddUserCard from "./AddUserCard"
import Alert from "./Alert"

export default function Admin(){
    const login = useSelector(store => store)
    const [users,setUsers] = useState([])
    const [companyID,setCompanyID] = useState(null)
    const [maxUsers,setMaxUsers] = useState(0)
    const [alert, setAlert] = useState({status:'',error:'',typeWaring:null,show:false})
    
    async function getAllUsers(){
        await fetch('/company/getAllUsersCompany',{
            headers: {
                'Content-Type': 'application/json',
                'autoriztion-token':login
            }}).then(response=>{
                if(response.status === 200){
                    //console.log(response);
                    response.json().then(res=>{
                        //console.log(res);
                        setUsers(res)
                        //console.log('users',users);
                    })
                }
            })}
    async function getIdCompany(){
        await fetch('/company/getid',{
            headers: {
                'Content-Type': 'application/json',
                'autoriztion-token':login
                }}).then(response=>{
                if(response.status === 200){
                    response.text().then(res=>{
                        setCompanyID(res)
                    })
                }else{
                    return null
                }})}
    async function getMaxUsers(){
        await fetch('/company/getMaxUser',{
            headers: {
                'Content-Type': 'application/json',
                'autoriztion-token':login
            },
        }).then(response=>{
            if(response.status === 200){
                response.json().then(res=>{
                    setMaxUsers(res.maxUser)
                })
            }else{
                console.log(response.status)
            }
        })
    }

    useEffect(()=>{
        getAllUsers()
        getIdCompany()
        getMaxUsers()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    async function deleteUser(id){
        await fetch(`/user/deleteUser/${id}`,{
            method: 'DELETE',
            headers:{
                'Content-Type': 'application/json',
                    'autoriztion-token':login
            }}).then(response=>{
                if(response.status === 200){
                    console.log('usuario deletado')
                    setUsers(users.filter(element=> element._id !== id))
                    setAlert({status:'sucesso',
                    error:'usuario deletado com sucesso',
                    typeWaring:200,show:true})
                }else{
                    response.text().then(res=>{
                        console.log(res);
                        setAlert({status:response.status,error:res,type:null,show:true})
                    })
                }
            })
    }

    async function editUser(id,update){
        await fetch(`/user/editUser/${id}`,{
            method:"POST",
            headers:{
                'Content-Type': 'application/json',
                    'autoriztion-token':login
            },
            body: JSON.stringify(update),
        }).then((response)=>{
            //console.log(response);
            if(response.status === 200){
                setAlert({status:'sucesso',
                    error:'usuario editado com sucesso',
                    typeWaring:200,show:true})
                getAllUsers()
            }else{
                response.json().then((res)=>{
                    //console.log(res)
                    setAlert({status:response.status,error:res,type:null,show:true})
                })
            }
            
        })
    }


    return(
    <div className="accordion accordion-flush" id="accordionFlushExample">
        <Alert alert={alert} setAlert={setAlert}/>
        {maxUsers>users.length?<AddUserCard key={-1} getAllUsers={getAllUsers} login={login} idCompany={companyID} setAlert={setAlert}/> : <div/>} 
        {users.map((element,index)=> <UserCard editUser={editUser} delete={deleteUser} key={index} index={index} user={element}/>)}
    </div>
    )
}