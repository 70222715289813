import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isTokenValid } from '../actions/loginAction'

export default function PrivateRouteLoged(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  useEffect(() => {
    async function checkToken() {
      await dispatch(isTokenValid());
    }
    checkToken();
  }, [dispatch]);

  const login = useSelector(store => store);
  useEffect(() => {
    if (login === null) {
      navigate('/acessoNegado');
    }
  }, [login, navigate]);

  return login ? (<div>{props.children}</div>) : null;
}