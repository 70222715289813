import React from "react";
import { useSelector } from "react-redux";
export default function SuperAdminAllUsers(props){
    const login = useSelector(store => store);
    let index = props.index
    //console.log(props.user);

    async function deleteUser(){
        await fetch(`/user/deleteUser/${props.user._id}`,{
            method: 'DELETE',
            headers:{
                'autoriztion-token':login
            }
        }).then(response=>{
            if(response.status === 200){
                props.getAllUser()
                props.setAlert({status:response.status,error:'Usuario deletado',typeWaring:response.status,show:true})
                console.log('usuario dleetado');
            }else{
                response.text().then(res=>{
                    console.log(res);
                    props.setAlert({status:response.status,error:res,typeWaring:response.status,show:true})
                })
            }
        })
    }

    async function editUserAdmin(){
        console.log(props.user.name);
        await fetch(`/user/editUser/${props.user._id}`,{
            method: 'POST',
            headers:{
                'Content-Type': 'application/json',
                'autoriztion-token':login
            },
            body:JSON.stringify({
                name: props.user.name, 
                admin: !props.user.admin
            })
        }).then(response=>{
            if(response.status === 200){
                props.getAllUser()
                props.setAlert({status:response.status,error:'Usuario alterado',typeWaring:response.status,show:true})
            }else{
                response.text().then(res=>{
                    console.log(res);
                    props.setAlert({status:response.status,error:res,typeWaring:response.status,show:true})
                })
            }
        })
    }

    async function editUserSuperAdmin(){
        await fetch(`/user/editUserSuperAdmin/${props.user._id}`,{
            method: 'POST',
            headers:{
                'Content-Type': 'application/json',
                'autoriztion-token':login
            },
            body:JSON.stringify({ 
                superAdmin: !props.user.superAdmin
            })
        }).then(response=>{
            if(response.status === 200){
                props.getAllUser()
                props.setAlert({status:response.status,error:'Usuario alterado',typeWaring:response.status,show:true})
            }else{
                response.text().then(res=>{
                    //console.log(res);
                    props.setAlert({status:response.status,error:res,typeWaring:response.status,show:true})
                })
            }
        })
    }

    return(
        <div className="accordion-item">
            <h2 className="accordion-header" id={`flush-heading${index}`}>
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse${index}`} aria-expanded="false" aria-controls={`flush-collapse${index}`}>
                    {props.user.name}
                </button>
            </h2>
            <div id={`flush-collapse${index}`} className="accordion-collapse collapse" aria-labelledby={`flush-heading${index}`} data-bs-parent="#accordionFlushExample">
                <div className="accordion-body">
                    <div>
                        id: {props.user._id}<br/>
                        nome: {props.user.name}<br/>
                        Email: {props.user.email}<br/>
                        Data de criação: {props.user.createDate}<br/>
                        id empresa: {props.user.id_companies}<br/>
                        Nome empresa: {props.user.nameCompany}<br/>
                        admin: {props.user.admin? 'Sim': 'Não'}<br/>
                        Super Admin: {props.user.superAdmin? 'Sim': 'Não'}<br/>
                        <button className="btn btn-warning me-2 mt-2" onClick={()=>{editUserAdmin()}}>{!props.user.admin?'Mudar para administrador':'Mudar para usuario comum'}</button>
                        <button className="btn btn-danger me-2 mt-2" onClick={()=>{editUserSuperAdmin()}}>{!props.user.superAdmin?'Mudar para Super adminstrador':'Tirar Super administrador'}</button>
                        <button className="btn btn-danger me-2 mt-2" onClick={()=>{deleteUser()}}>Delete</button>
                    </div>
                </div>
            </div>
        </div>
    )
}