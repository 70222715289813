import React, { useState } from "react"


export default function UserCard(props){
    let index = props.index
    const [edit,setEdit] = useState(false)
    
    function submitForm(event){
        event.preventDefault()
        const form = event.target
    const formData = new FormData(form)
    const jsonData = {}
    try{
        formData.forEach((value, key) => {
        jsonData[key] = value
        })
        if(jsonData.name === ''){
            jsonData.name = props.user.name
        }
        if(jsonData.password === ''){
            delete jsonData.password
        }
        console.log(jsonData);
        console.log(props.user._id)
        props.editUser(props.user._id,jsonData)
        setEdit(false)
    }catch(error){
        console.log('erro do userCard', error);
    }

}

function toLocateTtime(data){
    if(!data){return}
    const option = {timeZone: 'America/Sao_Paulo'}
    const formtData = new Date(data).toLocaleString('pt-BR', option)
    return formtData
}

    return edit? (
        <div className="accordion-item">
            <h2 className="accordion-header" id={`flush-heading${index}`}>
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse${index}`} aria-expanded="false" aria-controls={`flush-collapse${index}`}>
                {props.user.name}
            </button>
            </h2>
            <div id={`flush-collapse${index}`} className="accordion-collapse collapse" aria-labelledby={`flush-heading${index}`} data-bs-parent="#accordionFlushExample">
            <div className="accordion-body">
                <form onSubmit={submitForm}>
                    <div className="mb-3">
                        <label htmlFor="InputName" className="form-label">Nome</label>
                        <input type="text" className="form-control" id="InputName" name="name" aria-describedby="textHelp" placeholder={props.user.name}></input>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="InputPassword" className="form-label">Nova Senha</label>
                        <input type="password" className="form-control" id="InputPassword" name="password" aria-describedby="textHelp" placeholder='Nova Senha'></input>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="InputAdmin" className="form-label">Admin</label>
                            <select id="Inputadmin" name="admin" class="form-select form-select-sm" aria-label=".form-select-lg">
                                <option selected value={props.user.admin}>{props.user.admin?'Sim':'Não'}</option>
                                <option value={!props.user.admin}>{(!props.user.admin)?'Sim':'Não'}</option>
                            </select>
                    </div>
                    <button type="submit" className="btn btn-primary">Enviar</button> <button className="btn btn-danger" onClick={()=>{setEdit(false)}}>Cancelar</button>
                </form>
            </div>
            </div>
        </div>
) :(
        <div className="accordion-item">
            <h2 className="accordion-header" id={`flush-heading${index}`}>
            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse${index}`} aria-expanded="false" aria-controls={`flush-collapse${index}`}>
                {props.user.name}
            </button>
            </h2>
            <div id={`flush-collapse${index}`} className="accordion-collapse collapse" aria-labelledby={`flush-heading${index}`} data-bs-parent="#accordionFlushExample">
            <div className="accordion-body">
                Email: {props.user.email}<br/>
                Admin: {props.user.admin?'Sim':'Não'}<br/>
                Data de Criação: {toLocateTtime(props.user.createDate)}<br/>
                <button className="btn btn-primary" onClick={()=>{setEdit(true)}}>Editar</button> {!props.user.admin? <button onClick={()=>{props.delete(props.user._id)}} className="btn btn-danger">Deletar</button>:<button type="button" className="btn btn-danger" disabled>Deletar</button>} 
            </div>
            </div>
        </div>
    )
}