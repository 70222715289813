import React from "react";

export default function Alert(props){
    let typeWaring = ''
    if(props.alert.typeWaring === 200){
        typeWaring = 'success'
    }else{
        typeWaring = 'warning'
    }
    return(
        <div id="alert" className={`alert alert-${typeWaring} alert-dismissible fade ` + (props.alert.show? 'show':'')} role="alert">
            <strong>{props.alert.status}</strong> : {props.alert.error}
            <button type="button" className="btn-close" onClick={() => props.setAlert({status:'',error:'',show:false})}></button>
        </div>
    )
}