import React from "react";
import SuperAdminAllUsers from "./SuperAdminAllUsers";

export default function SuperAdminUser(props){
    let search = props.search
    return(
        <div className="accordion accordion-flush" id="accordionFlushExample">
            {props.users.map((user,index)=>{return((user.name.toLowerCase().includes(search.toLowerCase()))||(user.nameCompany.toLowerCase().includes(search.toLowerCase()))?(<SuperAdminAllUsers setAlert={props.setAlert} getAllUser={props.getAllUser} user={user} index={index} key={index}/>):(<div key={-2}></div>))})}
        </div>
    )
}