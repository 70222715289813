import React, { useState } from "react"
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {isTokenValid} from '../actions/loginAction'
import Alert from "./Alert";

export default function Login(){
    const [alert, setAlert] = useState({status:'',error:'',show:false})
    const navigate = useNavigate()
    const dispatch = useDispatch();

    function set_localStorage(token){
        localStorage.setItem('token', token);
    }

    function handleSubmit(event){
        event.preventDefault()
        const form = (event.target)
        const formData = new FormData(form)
        const jsonData = {};
        for (const pair of formData.entries()) {
            jsonData[pair[0]] = pair[1];
          }
        //console.log(jsonData);
        fetch('/user/login',{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(jsonData)
        }).then(response=>{
            if(response.status === 200){
                const autoriztion_token = response.headers.get('autoriztion-token')
                //console.log(autoriztion_token)
                set_localStorage(autoriztion_token)
                dispatch(isTokenValid());

                navigate('/');
            }else{
                response.text().then(data=>{
                    setAlert({status:response.status,error:data,show:true})
                })
            }})
    }

    return (
        <div className="text-center">
            <main className="form-signin w-100 m-auto" style={{ maxWidth: '330px', padding: '15px' }}>
                <form onSubmit={handleSubmit}>
                    <h1 className="h3 mb-3 fw-normal">Sing in</h1>
                    <div className="form-floating">
                        <input type='email' name="email" className="form-control" id="form_email" placeholder="name@example.com"/>
                        <label htmlFor="form_email">Email address*</label>
                    </div>
                    <div className="form-floating">
                        <input type='password' name="password" className="form-control" id="form_password" placeholder="password"/>
                        <label htmlFor='form_password'>Password*</label>
                    </div>
                    <button className="w-100 btn btn-lg btn-primary mt-3" type="submit">Sing-in</button>
                </form>
            </main>
            <div>
                <Alert alert={alert} setAlert={setAlert}></Alert>
            </div>
        </div>
    )
}
