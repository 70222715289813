export function isTokenValid(){
    let token = localStorage.getItem('token')
    return (dispath)=>{
        if(!token){
            dispath(LogoutAction())
        }else{
            fetch('/user/istoken',{
                headers: {
                    'Content-Type': 'application/json',
                    'autoriztion-token': token
                },
            }).then(response=>{
                if(response.status === 200){
                    dispath(loginAction(token))
                }else{

                    dispath(LogoutAction())
                    }
                })
        }
    }
}

function loginAction(token){
    return {type: 'LOGIN', payload:token}
}
export function LogoutAction(){
    localStorage.removeItem('token')
    return {type: 'LOGOUT'}
}