export async function getUserName(token) {
    //console.log(token);
    if (!token) {
      return '';
    }
  
    const response = await fetch('/user/getNameUser', {
      headers: {
        'Content-Type': 'application/json',
        'autoriztion-token': token
      },
    });
  
    if (response.status === 200) {
      const res = await response.text();
      //console.log('Name:', res);
      return res;
    } else {
      return '';
    }
  }

  export async function getAdmin(token){
    if (!token) {
      return false;
    }

    const response = await fetch('/user/isAdmin',{
      headers: {
        'Content-Type': 'application/json',
        'autoriztion-token': token
      }
    })
    if (response.status === 200) {
      return true;
    } else {
      return false;
    }

  }

  export async function getSuperAdmin(token){
    if (!token) {
      return false;
    }

    const response = await fetch('/user/isSuperAdmin',{
      headers: {
        'Content-Type': 'application/json',
        'autoriztion-token': token
      }
    })
    if (response.status === 200) {
      return true;
    } else {
      return false;
    }

  }

  