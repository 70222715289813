import React, { useState } from "react";
import { useSelector } from "react-redux"
import CardGrid from "./CardGrid";
import { useNavigate } from "react-router-dom";


export default function Pix(){
    const navigate = useNavigate()
    const [transactions,settransactions] = useState([])
    const login = useSelector(store => store)

    function toLocateTtime(data){
        if(!data){return}
        const option = {timeZone: 'America/Sao_Paulo'}
        const formtData = new Date(data).toLocaleString('pt-BR', option)
        return formtData
    }


    function handleSubmit(event){
        event.preventDefault()
        const form = (event.target)
        const formData = new FormData(form)
        const jsonData = {};
        for (const pair of formData.entries()) {
            jsonData[pair[0]] = pair[1];
          }
          //console.log(jsonData);
          if(jsonData.valor === ''){
            return
          }

          fetch(`/company/getTrasnaction/${jsonData.valor}`,{
            headers: {
                'Content-Type': 'application/json',
                'autoriztion-token':login
            }
          }).then(response=>{
            //console.log(response)
            if(response.status !== 200){
                navigate('/');
            }
            response.json().then(res=>{
                //console.log(res);
                settransactions(res)
            })
          })
    }

    return(
        <div>
            <form onSubmit={handleSubmit}>
                <div className="input-group mb-3">
                    <span className="input-group-text">R$</span>
                    <input type='number' step="0.01" className="form-control mx-sm-1" placeholder='200.5' name="valor"></input>
                    <div className="input-group-append">
                        <button className="btn btn-primary ms-1" type="submit">Pesquisar</button>
                    </div>
                </div>
            </form>
            <div>
                <CardGrid transactions={transactions}/>
                <h3>Utlima atualização: {toLocateTtime(transactions.lastUpDate)}</h3>
            </div>



        </div>
    )
}