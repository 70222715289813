import './App.css';
import React, { useEffect } from 'react'
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import {createStore,applyMiddleware} from 'redux'
import { Provider } from 'react-redux';
import thunk from 'redux-thunk'

import loginReducer from './reducers/loginReducer'
import Nav from './components/Nav';
import Footer from './components/Footer';
import Login from './components/Login';
import Pix from './components/Pix'
import PrivateRouteLoged from './components/PrivateRouteLoged';
import Home from './components/Home'
import NF from './components/NotFound';
import AcessDenid from './components/AcessDenid';
import AdminCompany from './components/AdminCompany';

import {isTokenValid} from './actions/loginAction'
import AdminUsers from './components/AdminUsers';
import PrivateRouteAdmin from './components/PrivateRouterAdmin';
import Body from './components/Body';
import SuperAdmin from './components/SuperAdmin';



const store = createStore(loginReducer,applyMiddleware(thunk))


function App() {

  async function checkToken() {
    const validToken = await store.dispatch(isTokenValid())
    if (!validToken) {
      //navigate('/login')
    }
  }
  
  useEffect(() => {
    checkToken()
  }, [])
  


  return (
    <Provider store={store}>
      <Router>
        <div className="App d-flex flex-column vh-100">
          <Routes>
            <Route path='/' element={
              <div>
                <Nav/>
                  <Home/>
              </div>
            }/>
            <Route path='/login' element={
              <Login/>
            } />
            <Route path='/pix' element={
              <div>
                <Nav/>
                <Body>
                  <PrivateRouteLoged><Pix/></PrivateRouteLoged>
                </Body>
              </div>
            }/>
            <Route path='/adminUser' element={
              <div>
                <Nav/>
                <Body>
                  <PrivateRouteAdmin><AdminUsers/></PrivateRouteAdmin>
                </Body>
              </div>
            }/>
            <Route path='/adminCompany' element={
              <div>
                <Nav/>
                <Body>
                  <PrivateRouteAdmin><AdminCompany/></PrivateRouteAdmin> 
                </Body>
              </div>
            }/>
            <Route path='/superadmin' element={
              <div>
                <Nav/>
                <Body>
                  <PrivateRouteLoged><SuperAdmin/></PrivateRouteLoged>
                </Body>
            </div>
            }/>
            <Route path='/acessoNegado' element={
            <div>
              <Nav/>
              <Body>
                <AcessDenid/>
              </Body>
            </div>
            }/>
            <Route path='*' element={
            <div>
              <Nav/>
              <Body>
                <NF/>
              </Body>
            </div>
            }/>
          </Routes>
          <Footer/>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
