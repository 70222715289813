import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Link, useNavigate } from 'react-router-dom'
import { LogoutAction } from '../actions/loginAction'
import { getUserName, getAdmin, getSuperAdmin } from '../actions/userFunctions'
import { isTokenValid } from '../actions/loginAction'
export default function LoginBotton() {
  const [nameUser, setNameUser] = useState(null);
  const [admin, setadmin] = useState(false);
  const [superAdmin, setsuperAdmin] = useState(false);
  const dispatch = useDispatch()
  const navigate = useNavigate()

  async function checkToken() {
    await dispatch(isTokenValid());
  }
  checkToken();

  const login = useSelector(store => store);

  useEffect(() => {
    async function checkToken(login) {
      //console.log(login);
      if (login != null) {
        await getUserName(login).then(res => {
          //console.log(res);
          setNameUser(res);
        });
        await getAdmin(login).then(res => {
          setadmin(res);
        });
        await getSuperAdmin(login).then(res => {
          setsuperAdmin(res);
        });
      }
    }
    if (login) {
      checkToken(login);
    }
  }, [dispatch, login, navigate]);
  
  return login ? (
    <div className="dropdown text-end dropdown-menu-end">
      <Link target="_top" className="d-block link-light text-decoration-none dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">{nameUser}</Link>
      <ul className="dropdown-menu text-small dropdown-menu-end">
        <li>
          <Link className="dropdown-item" to="/pix" onClick={(event) => {
            event.preventDefault();
            navigate("/pix");
          }}>PIX</Link>
        </li>
        {admin && (
          <li>
            <Link
              className="dropdown-item"
              to="/adminUser"
              onClick={(event) => {
                event.preventDefault();
                navigate("/adminUser");
              }}>Configuração usuarios
            </Link>
          </li>
          
        )}
        {admin && (
          <li>
            <Link
              className="dropdown-item"
              to="/adminCompany"
              onClick={(event) => {
                event.preventDefault();
                navigate("/adminCompany");
              }}>Configuração Empresa
            </Link>
          </li>
          
        )}
        {superAdmin && (
          <li>
            <Link
              className="dropdown-item"
              to="/superadmin"
              onClick={(event) => {
                event.preventDefault();
                navigate("/superadmin");
              }}
            >
              Superadmin
            </Link>
          </li>
        )}
        <li>
          <hr className="dropdown-divider" />
        </li>
        <li>
          <Link target="_top" to='/' className="dropdown-item" onClick={() => { dispatch(LogoutAction()) }}>Sign out</Link>
        </li>
      </ul>
    </div>
  )
    : (
      <div>
        <Link className="btn btn-outline-light me-2" to='/login'>login</Link>
      </div>
    )
}
