import React, { useState } from "react";

export default function SuperAdminModalDelete(props) {
  const active = props.modal
  const [input, setInput] = useState('');

  return (
    <>
      {active && (
        <>
        <div className="modal-backdrop show" style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", zIndex: "1000" }}>
        </div>
        <div className="modal" tabIndex="-1" role="dialog" style={{ display: "block", zIndex: "1001" }}>
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">Deletar empresa</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={()=>{props.setModal(false)}}></button>
              </div>
              <div className="modal-body">
                <label>Escreva o nome da empresa: {props.company} para deletar</label>
                <br/>
                <input value={input} onChange={(event)=>{setInput(event.target.value)}}/>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-danger" disabled={input === props.company ? false : true} onClick={()=>{props.deleteCompany()}}>Delete</button>
              </div>
            </div>
          </div>
        </div>
        </>
      )}
    </>
  );
}
