import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

export default function AcessDenid() {
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setRedirect(true);
    }, 4000);
  }, []);

  if (redirect) {
    return <Navigate to={{ pathname: "/" }} />;
  }

  return <h1 className="page">Acesso negado</h1>;
}
