import React from "react"

export default function AddUserCard(props) {
  if (props.idCompany === null) {
    return null
  }

  async function addUser(event) {
    event.preventDefault()
    const form = event.target
    const formData = new FormData(form)
    const jsonData = {}
    try{
        formData.forEach((value, key) => {
        jsonData[key] = value
        })

        jsonData.id_companies = props.idCompany
        //console.log(jsonData)
        await fetch('/user/register',{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'autoriztion-token':props.login
            },
            body: JSON.stringify(jsonData)
            
        }).then(response=>{
            if(response.status === 200){
                props.getAllUsers()
                props.setAlert({status:'sucesso',
                    error:'Usuario adicionando com sucesso',
                    typeWaring:200,show:true})
            }else{
                response.text().then(res=>{
                    props.setAlert({status:response.status,
                        error:res,
                        type:null,show:true})
                })
            }
        })
    }catch(error){

    }
    
  }

  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id="flush-headingAddUser">
        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseAddUser" aria-expanded="false" aria-controls="flush-collapseAddUser">
          ADD Usuario
        </button>
      </h2>
      <div id="flush-collapseAddUser" className="accordion-collapse collapse" aria-labelledby="flush-headingAddUser" data-bs-parent="#accordionFlushExample">
        <div className="accordion-body">
          <form onSubmit={addUser}>
            <div className="mb-3">
              <label htmlFor="InputName" className="form-label">Nome</label>
              <input type="text" className="form-control" id="InputName" name="name" aria-describedby="textHelp" />
            </div>
            <div className="mb-3">
              <label htmlFor="InputEmail" className="form-label">Email</label>
              <input type="email" className="form-control" id="InputEmail" name="email" aria-describedby="emailHelp" />
            </div>
            <div className="mb-3">
              <label htmlFor="InputPassword" className="form-label">Senha</label>
              <input type="password" className="form-control" id="InputPassword" name="password" />
            </div>
            <button type="submit" className="btn btn-primary">Enviar</button>
          </form>
        </div>
      </div>
    </div>
  )
}
