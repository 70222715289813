import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SuperAdminCompany from "./SuperAdminCompany";
import Alert from './Alert'
import SuperAdminUser from "./SuperAdminUser";

export default function SuperAdmin(){
    const login = useSelector(store => store);
    const [state,setState] = useState('company')
    const [companys,setCompanys] = useState([])
    const [users,setUsers] = useState([])
    const [search,setSearch] = useState('')
    const [alert, setAlert] = useState({status:'',error:'',typeWaring:null,show:false})
    
    async function getAllCompany(){
        await fetch('/company/getAllCompany',{
            headers:{
                'autoriztion-token':login
            }
        }).then(response=>{
            if(response.status === 200){
                response.json().then(res=>{
                    //console.log('1');
                    setCompanys(res)
                })
            }else{
                response.text().then(res=>{
                    setAlert({status:response.status,error:res,typeWaring:response.status,show:true})
                    //console.log(res);
                })
            }
        })
    }

    function setNameCompanyOnUser(res) {
        //console.log('3');
        const usersWithCompanyNames = res.map(user => {
          const nameCompany = companys.find(company => company._id === user.id_companies)?.name || 'nao encontrado';
          return {...user, nameCompany};
        });
        setUsers(usersWithCompanyNames);
      }
      
      

    async function getAllUser(){
        await fetch('/company/getAllUsers',{
            headers:{
                'autoriztion-token':login
            }
        }).then(response=>{
            if(response.status === 200){
                response.json().then(res=>{
                    //console.log('2');
                    //console.log(res);
                    setNameCompanyOnUser(res)
                    //console.log(users);
                })
            }else{
                response.text().then(res=>{
                    setAlert({status:response.status,error:res,typeWaring:response.status,show:true})
                })
            }
        })
    }

    async function getall(){
        const companyPromise = getAllCompany();
        const userPromise = getAllUser();
        
        await companyPromise;
        await userPromise;
    }

    useEffect(() => {  
        getall();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return(
        <div>
            <div>
                <ul className="d-flex justify-content-center list-unstyled">
                    <li>
                        <button className="btn btn-primary me-3" onClick={()=>{setState('company')}}>Empresas</button>
                    </li>
                    <li>
                        <button className="btn btn-primary me-3"onClick={()=>{setState('user')
                            getAllUser()}}>Usuarios</button>
                    </li>
                </ul>
            </div>
            <Alert alert={alert} setAlert={setAlert}/>
            <div>
                <form onSubmit={(event)=>{event.preventDefault()}}>
                    <input onChange={(e)=>{setSearch(e.target.value)}} className="form-control" type="search" placeholder={(() => {
                        switch (state) {
                        case "company":
                            return ('Nome da empresa OU CNPJ')
                        case "user":
                            return ('Nome do usuario OU empresa')
                        default:
                            return ('')
                        }
                    })()} aria-label="Search"></input>
                </form>
            </div>
            <div className="accordion accordion-flush" id="accordionFlushExample">
                {(() => {
                        switch (state) {
                        case "company":
                            return (<SuperAdminCompany setAlert={setAlert} getAllCompany={getall} search={search} companys={companys} setCompanys={setCompanys}/>)
                        case "user":
                            return (<SuperAdminUser setAlert={setAlert} users={users} getAllUser={getAllUser} search={search}/>)
                        default:
                            return (<div></div>)
                        }
                    })()}
            </div>

        </div>
    )
}