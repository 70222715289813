import React, { useEffect,useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { isTokenValid } from '../actions/loginAction'
import { getAdmin } from '../actions/userFunctions'
export default function PrivateRouteAdmin(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [admin, setadmin] = useState(false);

//   useEffect(() => {
//     async function checkToken() {
//       await dispatch(isTokenValid());
//       await getAdmin(login).then(res => {
//         setadmin(res);
//       })
//     }
//     checkToken();
//   }, [dispatch,login]);

async function checkToken() {
    await dispatch(isTokenValid());
  }
  checkToken();

const login = useSelector(store => store);
  useEffect(() => {
    if (login === null) {
      navigate('/acessoNegado');
    }else{
        async function gettokens(){
        await getAdmin(login).then(res => {
            setadmin(res);
          })}
          gettokens()
    }
  }, [login, navigate]);

  return ( login && admin) ? (<div>{props.children}</div>) : null;
}