import React, { useState } from "react";
import { Link } from "react-router-dom";
import LoginBotton from "./LoginBoton";

export default function Nav() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <nav className="navbar navbar-expand-md navbar-dark bg-secondary">
      <div className="container-fluid">
        <div className="d-flex align-items-center">
          <Link className="navbar-brand" to="/">
            <h1>PIXLINE</h1>
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            onClick={() => setIsOpen(!isOpen)}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
        <div className={`collapse navbar-collapse ${isOpen && "show"} mx-auto`}>
          <ul className="navbar-nav">
            <li className="nav-item">
              <Link
                className="nav-link"
                to="/"
                onClick={() =>{ 
                  window.location.href = '#Facilidade'
                  setIsOpen(false)}}
              >
                Sobre
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link"
                to="/"
                onClick={() =>{ 
                  window.location.href = '#comoFunciona'
                  setIsOpen(false)}}
              >
                Como funciona
              </Link>
            </li>
          </ul>
        </div>
        <div className="order-last">
          <LoginBotton />
        </div>
      </div>
    </nav>
  );
}
