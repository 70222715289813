import React from 'react';

function toLocateTtime(data){
    if(!data){return}
    const option = {timeZone: 'America/Sao_Paulo'}
    const formtData = new Date(data).toLocaleString('pt-BR', option)
    return formtData
}

function printCard(event, cardHtml) {
    const newWindow = window.open('', '_blank', 'height=500,width=500');
    newWindow.document.write(`
        <!DOCTYPE html>
        <html>
            <head>
                <title>Card Print</title>
            </head>
            <body>
                ${cardHtml}
            </body>
        </html>
    `);
    newWindow.print();
}

function CardGrid(props) {
    if(props.transactions.transaction == null){
        return(<div></div>)
    }else{
        const transactions = props.transactions.transaction;
        
        const transactionCards = transactions.map((transaction, index) => (
            <div key={index} className="col mb-4">
                <div className="card text-bg-primary mb-3" style={{ maxWidth: '18rem' }}>
                    <div className="card-header">
                        {transaction.name}
                    </div>
                    <div className="card-body">
                        <div className="print-content">
                        <button className='btn btn-secondary' onClick={(event) => printCard(event, event.target.parentNode.parentNode.parentNode.innerHTML)} style={{float: 'right'}}>Imprimir</button>
                            <h5 className="card-title">{transaction.tipo_operacao}</h5>
                            <p className="card-text">Valor: R$ {transaction.valor.toFixed(2)}</p>
                            <p className="card-text">{toLocateTtime(transaction.data)}</p>
                        </div>
                    </div>
                </div>
            </div>
        ));

        const rows = [];
        for (let i = 0; i < transactionCards.length; i += 3) {
            const row = (
                <div key={i / 3} className="row">
                    {transactionCards.slice(i, i + 3)}
                </div>
            );
            rows.push(row);
        }

        return (<div className="container">{rows}</div>);
    }
}

export default CardGrid;
