import React, { useState } from "react";
import { useSelector } from "react-redux";
import SuperAdminModalDelete from "./SuperAdminModalDelete";

export default function SuperAdminAllCompany(props){
    let index = props.index
    const login = useSelector(store => store);
    const [edit,setEdit] = useState(false)
    const [cnpj, setCnpj] = useState(props.company.cnpj);
    const [modal,setModal] = useState(false)
    function handleCnpjChange(event){
        let cnpj = event.target.value;
        cnpj = cnpj.replace(/\D/g, ''); // remove tudo que não é dígito
        cnpj = cnpj.substring(0, 14); // limita o comprimento máximo do CNPJ a 14 caracteres
        cnpj = cnpj.replace(/^(\d{2})(\d)/, '$1.$2'); // adiciona ponto após o segundo dígito
        cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3'); // adiciona ponto após o quinto dígito
        cnpj = cnpj.replace(/\.(\d{3})(\d)/, '.$1/$2'); // adiciona barra após o oitavo dígito
        cnpj = cnpj.replace(/\/(\d{4})(\d)/, '/$1-$2'); // adiciona traço após o penúltimo dígito
        setCnpj(cnpj);
      }

      async function changeStatus(){
        let jsonData = props.company
        jsonData.status = !jsonData.status
        jsonData.nameCompany = jsonData.name
        //console.log(jsonData);
        await fetch('/company/updateSAdmin',{
            method: 'POST',
            headers:{
                'autoriztion-token':login,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(jsonData)
        }).then(response =>{
            if(response.status === 200){
                //console.log('sucesso')
                props.getAllCompany()
                props.setAlert({status:response.status,error:'alterada com sucesso',typeWaring:response.status,show:true})
            }else{
                response.text().then(res=>{
                    //console.log(response.status,res)
                    props.setAlert({status:response.status,error:res,typeWaring:response.status,show:true})
                })
            }
        })
      }

      async function handleEditSubmit(event){
        event.preventDefault()
        const form = event.target
        const formData = new FormData(form)
        let jsonData = {}
        try {
            formData.forEach((value, key) => {jsonData[key] = value})
            
            jsonData._id = props.company._id
            jsonData.status = (event.target.status.checked ? true : false)
            //console.log(jsonData);

        
            await fetch('/company/updateSAdmin',{
                method: 'POST',
                headers:{
                    'autoriztion-token':login,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(jsonData)
            }).then(response =>{
                if(response.status === 200){
                    //console.log('sucesso')
                    setEdit(false)
                    props.getAllCompany()
                    props.setAlert({status:response.status,error:'alterada com sucesso',typeWaring:response.status,show:true})
                }else{
                    response.text().then(res=>{
                        //console.log(response.status,res)
                        props.setAlert({status:response.status,error:res,typeWaring:response.status,show:true})
                    })
                }
            })
        } catch (error) {
            console.log(error);
            props.setAlert({status:'500',error:error,typeWaring:'500',show:true})
        }
      }

      async function deleteCompany(){
        try {
            await fetch(`/company/delete/${props.company._id}`,{
                method: 'DELETE',
                headers:{
                    'autoriztion-token': login
                }
            }).then(response=>{
                if(response.status === 200){
                    props.getAllCompany()
                    console.log('usuario deletado');
                    props.setAlert({status:response.status,error:'Empresa deletado com sucesso',typeWaring:response.status,show:true})
                }else{
                    response.text().then(res=>{
                        console.log(res);
                        props.setAlert({status:response.status,error:res,typeWaring:response.status,show:true})
                    })
                }
            })
        } catch (error) {
            
        }
      }

    return(
        <div className="accordion-item">
            <SuperAdminModalDelete modal={modal} setModal={setModal} company={props.company.name} deleteCompany={deleteCompany}/>
            <h2 className="accordion-header" id={`flush-heading${index}`}>
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse${index}`} aria-expanded="false" aria-controls={`flush-collapse${index}`}>
                    {props.company.name}
                </button>
            </h2>
            <div id={`flush-collapse${index}`} className="accordion-collapse collapse" aria-labelledby={`flush-heading${index}`} data-bs-parent="#accordionFlushExample">
            <div className="accordion-body">
                {edit?
                <div>
                    <form onSubmit={handleEditSubmit}>
                        <div className="mb-3">
                            <label htmlFor="InputCName" className="form-label">Nome da empresa</label>
                            <input type="text" className="form-control" id="InputCName" name="nameCompany" aria-describedby="textHelp" required defaultValue={props.company.name}/>
                        </div>
                        <div className="form-check form-switch mb-3">
                            <input className="form-check-input" type="checkbox" role="switch" name="status" id="flexSwitchCheckChecked" defaultChecked={props.company.status}/>
                            <label className="form-check-label" htmlFor="flexSwitchCheckChecked">Status</label>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="InputCNPJ" className="form-label me-1">CNPJ</label>
                            <input type="text" id="cnpj" name="cnpj" value={cnpj} placeholder="__.___.___/____-__" onChange={handleCnpjChange} required  />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="ImputmaxUser" className="form-label me-1">Quantidade Usuarios permitidos</label>
                            <input type="number" id="numberUsers" name="maxUser" defaultValue={props.company.maxUser} required/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="Inputclient_id" className="form-label">client_id</label>
                            <input type="text" className="form-control" id="client_id" name="client_id" aria-describedby="textHelp" required defaultValue={props.company.client_id}/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="Inputclient_secret" className="form-label">client_secret</label>
                            <input type="text" className="form-control" id="client_secret" name="client_secret" aria-describedby="textHelp" required defaultValue={props.company.client_secret}/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="Inputconta" className="form-label">conta</label>
                            <input type="text" className="form-control" id="conta" name="conta" aria-describedby="textHelp" required defaultValue={props.company.conta}/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="InputxcorrelationID" className="form-label">client_id</label>
                            <input type="text" className="form-control" id="xcorrelationID" name="xcorrelationID" aria-describedby="textHelp" required defaultValue={props.company.xcorrelationID}/>
                        </div>
                        <button type="submit" className="btn btn-success">Enviar</button>
                        <button className="btn btn-warning ms-3" onClick={()=>{setEdit(false)}}>Cancelar</button>
                    </form>
                </div>:
                <div>
                id: {props.company._id}<br/>
                cnpj: {props.company.cnpj}<br/>
                status: {props.company.status?<span className="text-success">Ativo</span>:<samp className="text-danger">Desativado</samp>}<br/>
                Quantidade Usuarios permitidos: {props.company.maxUser}<br/>
                client_id: <samp className="text-warning">{props.company.client_id}</samp><br/>
                client_secret: <samp className="text-warning">{props.company.client_secret}</samp><br/>
                conta: <samp className="text-warning">{props.company.conta}</samp><br/>
                xcorrelationID: <samp className="text-warning">{props.company.xcorrelationID}</samp><br/>
                Data de upload certificado: <samp className="text-warning">{props.company.dataUpdateCert}</samp><br/>
                Data de upload key: <samp className="text-warning">{props.company.dataUpdateKey}</samp><br/>
                {props.company.status?(<button className="btn btn-outline-danger" onClick={changeStatus}>desativar status</button>):(<button className="btn btn-outline-success" onClick={changeStatus}>ativar status</button>)} 
                <button className="btn btn-warning ms-3" onClick={()=>{setEdit(true)}}>Edit</button>
                <button className="btn btn-danger ms-3" onClick={()=>{setModal(true)}}>Delete</button>  
                </div>}
            </div>
            </div>
        </div>
    )
}