import React from "react";
import imagem from '../assets/images/firs_image.jpeg'
import mulher_feliz from '../assets/images/mulher_feliz.png'
import mulher_celular from '../assets/images/moca_celular.png'
export default function Home(){
    return(
        <div>
            <header className="">
                <div className="position-relative">
                    <img className="img-fluid" src={imagem} alt="imagem"/>
                    <div className="position-absolute top-0 start-0 w-100 h-100 d-flex align-items-center" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                        <div className="ms-3 text-white">
                            <h1>Pixline</h1>
                            <p>Simplifique a validação de pagamentos via PIX</p>
                        </div>
                    </div>
                </div>
            </header>
            <section id="Facilidade" className=" pt-5 container-fluid bg-secondary bg-opacity-10">
                <div className="row mb-5 align-items-cente">
                    <div className="col-md-6">
                        <h2>Facilidade, segurança e agilidade</h2>
                        <p>Apresentamos a PixLine, um serviço sofisticado e eficiente que simplifica a rotina de empresários e funcionários ao confirmar pagamentos via PIX e deposito de seus clientes.</p>
                        <p>Proporcionamos facilidade, segurança e agilidade, com uma proposta diferenciada: em vez de cobrar por verificação, como outras empresas fazem, oferecemos uma taxa mensal fixa, sem custos adicionais.</p>
                    </div>
                    <div className="col-md-6 position-relative image-container" style={{minHeight: '300px'}}>
                        <img src={mulher_feliz} alt="Imagens de pessoas trabalhando felizes" className="img-fluid"/>
                    </div>
                </div>
            </section>
            <section id="comoFunciona" className="mt-5 container-fluid">
                <div className="row mb-5 d-flex align-items-center">
                    <div className="col-md-6 position-relative image-container" style={{minHeight: '300px'}}>
                        <img src={mulher_celular} alt="Imagem ilustrativa" className="img-fluid"/>
                    </div>
                    <div className="col-md-6">
                        <h2>Como funciona a PixLine?</h2>
                        <ol>
                            <li>Juntamente com você entramos em contato com o gerente da sua conta e solicitamos uma chave de autenticação para a conta desejada. Fique tranquilo, pois essa chave não é sua senha e serve apenas para verificação das transferências.</li>
                            <li>Nosso servidor verifica as transferências realizadas nos últimos 7 dias, a cada segundo.</li>
                            <li>Seus funcionários inserem o valor que deseja checar</li>
                        </ol>
                        <div className="d-flex justify-content-center align-items-center">
                            <h3>E pronto</h3>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}