import React, { useState } from "react";
import { useSelector } from "react-redux";
export default function SuperAdminAddCompany(props){
    const login = useSelector(store => store)
    const [cnpj, setCnpj] = useState('');
    function handleCnpjChange(event){
        let cnpj = event.target.value;
        cnpj = cnpj.replace(/\D/g, ''); // remove tudo que não é dígito
        cnpj = cnpj.substring(0, 14); // limita o comprimento máximo do CNPJ a 14 caracteres
        cnpj = cnpj.replace(/^(\d{2})(\d)/, '$1.$2'); // adiciona ponto após o segundo dígito
        cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3'); // adiciona ponto após o quinto dígito
        cnpj = cnpj.replace(/\.(\d{3})(\d)/, '.$1/$2'); // adiciona barra após o oitavo dígito
        cnpj = cnpj.replace(/\/(\d{4})(\d)/, '/$1-$2'); // adiciona traço após o penúltimo dígito
        setCnpj(cnpj);
      }

      async function submitForm(event){
        event.preventDefault()
        const form = event.target
        const formData = new FormData(form)
        const jsonData = {}
        try {
            formData.forEach((value, key) => {
                jsonData[key] = value
                })
                console.log(jsonData);
                await fetch('/company/register',{
                    method:"POST",
                    headers:{
                        'Content-Type':'application/json',
                        'autoriztion-token': login
                    },
                    body:JSON.stringify(jsonData)
                    
                }).then(response=>{
                    if(response.status === 200){
                        props.getAllCompany()
                        console.log('sucesso');
                        props.setAlert({status:response.status,error:'Empressa adicionada com suecesso',typeWaring:response.status,show:true})
                    }else{
                        response.text().then(res=>{
                            console.log(response.status,res)
                            props.setAlert({status:response.status,error:res,typeWaring:response.status,show:true})
                        })
                    }
                })

        } catch (error) {
            console.log(error);
        }
      }

    return(
    <div className="accordion-item">
        <h2 className="accordion-header" id="flush-headingAddUser">
        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseAddUser" aria-expanded="false" aria-controls="flush-collapseAddUser">
            ADD Company
        </button>
        </h2>
        <div id="flush-collapseAddUser" className="accordion-collapse collapse" aria-labelledby="flush-headingAddUser" data-bs-parent="#accordionFlushExample">
            <div className="accordion-body">
                <form onSubmit={submitForm}>
                    <div className="mb-3">
                        <label htmlFor="InputCName" className="form-label">Nome da empresa</label>
                        <input type="text" className="form-control" id="InputCName" name="nameCompany" aria-describedby="textHelp" required/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="InputCNPJ" className="form-label">CNPJ</label>
                        <input type="text" id="cnpj" name="cnpj" value={cnpj} placeholder="__.___.___/____-__" onChange={handleCnpjChange} required  />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="name_user" className="form-label">Nome do usuario admin</label>
                        <input type="text" className="form-control" id="name_user" name="name" aria-describedby="textHelp" required />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="InputEmail" className="form-label">Email</label>
                        <input type="email" className="form-control" id="InputEmail" name="email" aria-describedby="emailHelp" />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="InputPassword" className="form-label">Senha</label>
                        <input type="password" className="form-control" id="InputPassword" name="password" />
                    </div>
                        <button type="submit" className="btn btn-primary">Enviar</button>
                </form>
            </div>
        </div>
    </div>
    )
}