import React from "react";
import SuperAdminAddCompany from "./SuperAdminAddCompany";
import SuperAdminAllCompany from './SuperAdminAllCompany'

export default function SuperAdminCompany(props){
    let search = props.search
    return(
        <div className="accordion accordion-flush" id="accordionFlushExample">
            <SuperAdminAddCompany key={-1} setAlert={props.setAlert} getAllCompany={props.getAllCompany}/>
            {props.companys.map((company,index)=>{return (company.name.toLowerCase().includes(search.toLowerCase()) || company.cnpj.includes(search))?(<SuperAdminAllCompany setAlert={props.setAlert} getAllCompany={props.getAllCompany} company={company} key={index} index={index}/>):(<div key={-2}></div>)})}
        </div>
    )
}