import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux"
import Alert from "./Alert";

export default function AdminCompany(){
    const login = useSelector(store => store)
    const [company,setCompany] = useState({})
    const [alert, setAlert] = useState({status:'',error:'',typeWaring:null,show:false})
    
    function editCompanyAtribut(value,atribut){
        let newCompany = {
            ...company,
            [atribut]: value
        }
        setCompany(newCompany)
        //console.log( atribut,value);

    }

    async function getCompany(){
        await fetch('/company/getcompany',{
            headers: {
                'Content-Type': 'application/json',
                'autoriztion-token':login
            }}).then(response=>{
                if(response.status === 200){
                    response.json().then(res=>{
                        setCompany(res)
                        //console.log(res);
                    })
                }else{
                    response.text().then(res=>{
                        //console.log(response.status,res);
                        setAlert({status:response.status,error:res,typeWaring:null,show:true})
                    })
                }
            })
    }

    async function setCompanyBanc(event){
        event.preventDefault()
        const form = event.target
        const formData = new FormData(form)
        const jsonData = {}
        try{
            formData.forEach((value, key) => {
            jsonData[key] = value
            })
            jsonData.cnpj = company.cnpj

        await fetch('/company/update',{
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'autoriztion-token':login
            },
            body: JSON.stringify(jsonData)

        }).then(response=>{
            if(response.status === 200){
                //console.log('deu certo');
                setAlert({status:response.status,error:'alteração bem sucedida',typeWaring:200,show:true})
            }else{
                response.text().then(res=>{
                    //console.log(response.status,res);
                    setAlert({status:response.status,error:res,typeWaring:null,show:true})
                })
            }
        })
        }catch(error){

        }
    }

    async function sendCertOrKey(event){
        event.preventDefault()
        const form = event.target
        const formData = new FormData()
        const file = form[0].files[0]
        if(!file){
            //console.log('nao há arquivo');
            setAlert({status:'400',error:'Não há arquivo',typeWaring:null,show:true})
            return
        }
        formData.append("file", file)
        fetch('/company/uploadCertKey',{
            method: 'POST',
            headers: {'autoriztion-token':login},
            body: formData
        }).then(response=>{
            if(response.status === 200){
                //console.log('tudo certo')
                setAlert({status:response.status,error:'arquivo alterado com sucesso',typeWaring:200,show:true})
                getCompany()
            }else{
                response.text().then(res=>{
                    setAlert({status:response.status,error:res,typeWaring:null,show:true})
                })
            }
        })
        
    }

    function toLocateTtime(data){
        if(!data){return}
        const option = {timeZone: 'America/Sao_Paulo'}
        const formtData = new Date(data).toLocaleString('pt-BR', option)
        return formtData
    }

    useEffect(()=>{
        getCompany()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return(
        <div>
            <div>
                <h3>Configuração Bancaria</h3>
                <form onSubmit={setCompanyBanc}>
                    <label className="form-label">Nome da empresa</label>
                    <input name="nameCompany" type="text" className="form-control" id="name-company" value={company.name} onChange={(e)=>{editCompanyAtribut(e.target.value,'name')}} ></input>
                    
                    <label className="form-label">client_secret</label>
                    <input name="client_secret" type="text" className="form-control" id="client-secret" value={company.client_secret} onChange={(e)=>{editCompanyAtribut(e.target.value,'client_secret')}}></input>
                    
                    <label className="form-label">client_id</label>
                    <input name="client_id" type="text" className="form-control" id="name_company" value={company.client_id} onChange={(e)=>{editCompanyAtribut(e.target.value,'client_id')}}></input>
                    
                    <label className="form-label">conta</label>
                    <input name="conta" type="text" className="form-control" id="conta" value={company.conta} onChange={(e)=>{editCompanyAtribut(e.target.value,'conta')}}></input>
                    
                    <label className="form-label">xcorrelationID</label>
                    <input name="xcorrelationID" type="text" className="form-control" id="xcorrelationID" value={company.xcorrelationID} onChange={(e)=>{editCompanyAtribut(e.target.value,'xcorrelationID')}}></input>
                    
                    <button type="submit" className="btn btn-primary mt-3 mb-3">Editar configuração bancaria</button>
                </form>
            </div>

            <div>
            <form onSubmit={sendCertOrKey} className="row g-3">
                <div className="col-auto">
                    <label className="form-control-plaintext">Cert</label>
                </div>
                <div className="col-auto">
                    <input name="file" className="form-control" type="file" id="formFile"/>        
                </div>
                <div className="col-auto">
                    <button type="submit" className="btn btn-primary mb-3">Enviar</button>
                </div>
                </form>
                <h5>data de envio: {toLocateTtime(company.dataUpdateCert)}</h5>
            </div>

            <div>
            <form onSubmit={sendCertOrKey} className="row g-3">
                <div className="col-auto">
                    <label className="form-control-plaintext">Key</label>
                </div>
                <div className="col-auto">
                    <input name="file" className="form-control" type="file" id="formFile"/>        
                </div>
                <div className="col-auto">
                    <button type="submit" className="btn btn-primary mb-3">Enviar</button>
                </div>
                </form>
                <h5>data de envio: {toLocateTtime(company.dataUpdateKey)}</h5>
            </div>
            <Alert alert={alert} setAlert={setAlert}/>
        </div>
    )
}